import styled from "styled-components"
import { device } from "breakpoints"
import { Button } from "../Button"
import EmblaCarouselReact from "embla-carousel-react"

export const CarouselContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${props => (props.gap ? `0 calc(-${props.gap / 2}px)` : `0 auto`)};

  ${props =>
    props.background &&
    `
    position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  `};

  ${props =>
    props.fade &&
    `
  margin:0;
  `}
`

export const EmblaComponent = styled(EmblaCarouselReact)`
  width: 100%;
  display: flex;
  justify-content: center;
  /* padding-right: ${props => `${props.theme.mobileGutter}px`}; */
  height: ${props => props.background && "100%"};

  @media ${device.desktop} {
    /* padding-right: ${props => `${props.theme.desktopGutter}px`}; */
  }

  @media ${device.large} {
    padding-right: 0;
  }

  ${props =>
    props.loop &&
    `
    padding-right:0 !important;
`};

  ${props =>
    props.fade &&
    `
    padding-right:0 !important;
`};
`
export const NextButton = styled(Button)`
  padding: 0;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: grey;
  position: relative;

  opacity: ${props => (props.disabled ? 0.3 : 1)};
  pointer-events: ${props => props.disabled && "none"};

  :hover {
    background: black;
  }

  svg {
    width: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0) scale(-1);
  }
`
export const PreviousButton = styled(NextButton)`
  margin-right: 20px;

  svg {
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
`

export const Slide = styled.div`
  position: relative;
  flex: 0 0 auto;
  /* pointer-events: all; */

  ${props =>
    props.fade &&
    `
  position: absolute;
  top: 0;
  left: 0 !important;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1500ms;
  counter-increment: embla;


  &.is-selected {
    opacity: 1;
    z-index: 1;
   transition: opacity 1500ms;
  }
  `};
`

export const SlidesContainer = styled.div`
  display: flex;
  flex-direction: row;
  will-change: transform;
  width: 100%;
  max-width: ${props => `calc(${props.theme.mainMaxWidth + props.gap}px)`};
  padding: ${props => `0 calc(${props.theme.mobileGutter}px)`};
  @media ${device.desktop} {
    padding: 0;
    width: ${props =>
      props.fade ? null : `calc(100% - 2*${props.theme.desktopGutter}px)`};
  }

  ${Slide} {
    padding: ${props => (props.gap ? `0 ${props.gap / 2}px` : null)};
    width: ${props =>
      props.slidesToShow[0]
        ? `${100 / props.slidesToShow[0]}%`
        : `${100 / props.slidesToShow}%`};

    @media ${device.tablet} {
      width: ${props =>
        props.slidesToShow.length === 3 &&
        props.slidesToShow[1] &&
        `${100 / props.slidesToShow[1]}%`};
    }

    @media ${device.desktop} {
      width: ${props =>
        props.slidesToShow.length === 2 &&
        props.slidesToShow[1] &&
        `${100 / props.slidesToShow[1]}%`};

      width: ${props =>
        props.slidesToShow.length === 3 &&
        props.slidesToShow[2] &&
        `${100 / props.slidesToShow[2]}%`};
    }
  }

  ${props =>
    props.loop &&
    `
    // @media ${device.desktop} {
    padding:0 !important;
    max-width: calc(100% + ${props.gap}px);
    width: calc(100% + ${props.gap}px);
    margin: 0 -${props.gap}px;
    // }
`};

  ${props =>
    props.fade &&
    `
    transform: none !important;
    width: 100%;
    max-width: ${props.theme.mainMaxWidth}px !important;
    height:600px;
    position:relative;

    ${Slide} {
      padding:0;
    }
`};

  ${props =>
    props.background &&
    `
    height:100%;
    width:100%;
    max-width:100% !important;
  `};
`

export const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Buttons = styled.div`
  /* margin-top: 30px; */
  display: flex;
`

const DotHeight = `18px`

export const Dots = styled.div`
  display: flex;
  /* margin-top: 30px; */
`

export const DotButton = styled.button`
  height: ${DotHeight};
  width: ${DotHeight};
  transition: 120ms;
  padding: 0;
  background: none;
  margin: 1px;
  cursor: pointer;
  /* outline: none; */
  border-radius: 50%;
  border: 2px solid
    ${props =>
      props.selected && props.dotColor
        ? props.dotColor
        : props.selected
        ? props.theme.primaryColor
        : "transparent"};
  position: relative;

  :before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: 120ms;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: ${props =>
      props.selected && props.dotColor
        ? props.dotColor
        : props.selected
        ? props.theme.primaryColor
        : "#D6E3E4"};
  }
`
